table {
  width: 100%;
  /* background-color: #e8e8e8; */
  border-spacing: 0px;
  border-collapse: collapse;
  /* margin: -2px; */
}

table td,
table th {
  border: solid 1px #e8e8e8 !important;
}

table th {
  /* background-color: #e8e8e8; */
  text-align: left;
  padding: 10px;
  max-width: 160px;
  min-width: 160px;
  width: 160px;
  color: rgba(0, 0, 0, 0.6);
}

table td div {
  width: 100%;
}

table div.error input, 
table div.error textarea {
  background-color: #fff6f6 !important;
}

table td input,
table td textarea,
table td select {
  width: 100%;
  border: 0px !important;
  border-radius: 0px !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
